<template>
  <base-layout>
    <section class="header">
      <img src="../assets/img/academy_logo.png" alt="">
    </section>
    <section v-show="thirdStep.length === 0" class="academy">
      <h2>«добрый» представляет уникальную платформу «академия супер»</h2>
      <p>Здесь ребенок может обучиться самым важным навыкам для успешного будущего! А заряд энергии для достижений дают продукты от «добрый»!</p>
      <div class="video">
        <vimeo-player style="width: 100%" :video-id="startVideo"></vimeo-player>
      </div>
    </section>

    <section v-show="secondStep.length === 0"  class="age">
      <h2>В «Академии супер» тебе доступны онлайн-уроки разных возрастных категорий. </h2>
      <p>Выбери, пожалуйста, возраст твоего ребенка:</p>
      <button @click="setSecondStep" class="button button-main">3-6 лет</button>
      <button @click="setSecondStepType2" class="button button-main">7-12 лет</button>
    </section>

<!--    <section v-for="item in secondStep" :key="item" class="categories">-->
<!--      {{ item }}-->
<!--        <div class="category-item">-->
<!--          <img src="../assets/svg/proger.svg" alt="">-->
<!--          <div class="category-item&#45;&#45;right">-->
<!--            <div class="title">Cупер-прогер</div>-->
<!--            <div @click="setThirdStep" class="button button-main">Начать обучение</div>-->
<!--          </div>-->
<!--        </div>-->
<!--    </section>-->

    <section v-if="secondStep.length === 2 && thirdStep.length === 0" class="categories">
      <h2 style="color: #fff">
        смотри уроки и зарабатывай баллы!<br>
        <span style="color: #3F3F3F">1 направление = 1 балл</span>
      </h2>
      <div class="category-item">
        <img src="../assets/svg/eco.svg" alt="">
        <div class="category-item--right">
          <div class="title">Cупер-эколог</div>
          <div @click="setThirdStep(secondStep[1])" class="button button-main">Начать обучение</div>
        </div>
      </div>
      <div class="category-item">
        <img src="../assets/svg/finance.svg" alt="">
        <div class="category-item--right">
          <div class="title">Cупер-финансист</div>
          <div @click="setThirdStep(secondStep[0])" class="button button-main">Начать обучение</div>
        </div>
      </div>
    </section>

    <section v-if="secondStep.length === 4 && thirdStep.length === 0" class="categories">
      <h2 style="color: #fff">
        смотри уроки и зарабатывай баллы!<br>
        <span style="color: #3F3F3F">1 направление = 1 балл</span>
      </h2>
      <div class="category-item">
        <img src="../assets/svg/finance.svg" alt="">
        <div class="category-item--right">
          <div class="title">Cупер-финансист</div>
          <div @click="setThirdStep(secondStep[0])" class="button button-main">Начать обучение</div>
        </div>
      </div>
      <div class="category-item">
        <img src="../assets/svg/eco.svg" alt="">
        <div class="category-item--right">
          <div class="title">Cупер-эколог</div>
          <div @click="setThirdStep(secondStep[1])" class="button button-main">Начать обучение</div>
        </div>
      </div>
      <div class="category-item">
        <img src="../assets/svg/proger.svg" alt="">
        <div class="category-item--right">
          <div class="title">Супер-прогер</div>
          <div @click="setThirdStep(secondStep[2])" class="button button-main">Начать обучение</div>
        </div>
      </div>
      <div class="category-item">
        <img src="../assets/svg/blogger.svg" alt="">
        <div class="category-item--right">
          <div class="title">Супер-блоггер</div>
          <div @click="setThirdStep(secondStep[3])" class="button button-main">Начать обучение</div>
        </div>
      </div>
    </section>

<!--    <section v-show="this.secondStep" class="categories">-->
<!--      <div class="category-item">-->
<!--        <img src="../assets/svg/proger.svg" alt="">-->
<!--        <div class="category-item&#45;&#45;right">-->
<!--          <div class="title">Cупер-прогер</div>-->
<!--          <div @click="setThirdStep" class="button button-main">Начать обучение</div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="category-item">-->
<!--        <img src="../assets/svg/finance.svg" alt="">-->
<!--        <div class="category-item&#45;&#45;right">-->
<!--          <div class="title">Cупер-финансист</div>-->
<!--          <div @click="setThirdStep" class="button button-main">Начать обучение</div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="category-item">-->
<!--        <img src="../assets/svg/blogger.svg" alt="">-->
<!--        <div class="category-item&#45;&#45;right">-->
<!--          <div class="title">Супер-блоггер</div>-->
<!--          <div @click="setThirdStep" class="button button-main">Начать обучение</div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="category-item">-->
<!--        <img src="../assets/svg/eco.svg" alt="">-->
<!--        <div class="category-item&#45;&#45;right">-->
<!--          <div class="title">Супер-эколог</div>-->
<!--          <div @click="setThirdStep" class="button button-main">Начать обучение</div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </section>-->

    <section v-if="thirdStep.length !== 0" class="category-videos">
<!--      {{ item.video }}-->
<!--      <vimeo-player :video-id='item.video'></vimeo-player>-->
      <h1>{{ thirdStep.cyrName }}</h1>
      <h2>тебе доступны уроки по данной теме:</h2>
      <div class="video" v-for="(item, idx) in thirdStep.lessons" :key="item" :class="{ 'disabled': item.enabled === 0 }">
        <vimeo-player @loaded="onPlayerLoaded" @ended="onPlayerEnded(idx)" :player-height="'300px'" :video-id='item.video'></vimeo-player>
        <div class="title">{{ item.name }}</div>
        <button @click="showAcademyTestFirstPopup(item.questions, idx)" class="button button-main">Пройти тест</button>
      </div>
      <h2>
        Для выполнения заданий необходимо скачать следующие материалы
      </h2>
      <a target="_blank" class="download-link" href="https://disk.yandex.ru/i/K9h25V8zPllAPA"><img src="../assets/svg/download.svg" alt="">скачать инструкцию для работы в Scratch</a>
      <br>
      <a target="_blank" class="download-link" href="https://disk.yandex.ru/d/es_HkK-V8JZIPg"><img src="../assets/svg/download.svg" alt="">скачать фоны и спрайты для игры</a>
    </section>
  </base-layout>
</template>

<script>
import BaseLayout from "@/layouts/BaseLayout"
import AcademyTestFirstPopup from "@/components/popups/AcademyTestFirstPopup";
import { vueVimeoPlayer } from "vue-vimeo-player"
import {viewLesson} from "@/api"

export default {
  name: "Academy",
  components: {
    BaseLayout,
    VimeoPlayer: vueVimeoPlayer
  },

  data() {
    return {
      firstStep: true,
      secondStep: [],
      thirdStep: [],
      childVideos: [],
      studentVideos: [],
      images: [],
      startVideo: 'https://player.vimeo.com/video/589980040?h=2d0821291e',
    }
  },

  methods: {
    showAcademyTestFirstPopup(test, lessonId) {
      this.$store.state.bus.curLesson = lessonId + 1
      this.$store.dispatch("bus/fillTest", test)
      this.$store.dispatch("bus/openPopup", "startTest")
    },
    setFirstStep() {
      this.firstStep = true;
    },
    setSecondStep() {
      if (! this.$store.getters["user/isLoggedIn"]) {
        this.$store.dispatch("bus/openPopup", "login")
        return
      }

      this.secondStep = this.childVideos
    },
    setSecondStepType2() {
      if (! this.$store.getters["user/isLoggedIn"]) {
        this.$store.dispatch("bus/openPopup", "login")
        return
      }

      this.secondStep = this.studentVideos
    },
    setThirdStep(lesson) {
      //this.secondStep = [];
      //this.firstStep = [];
      console.log(lesson)
      this.$store.state.bus.curCourse = lesson.id
      this.thirdStep = lesson;
    },
    onPlayerLoaded(q, w, e) {
      const frame = document.querySelectorAll(".video iframe")

      frame.forEach(function (item) {
        item.width = "100%"
        item.height = "250px"
        item.style.borderRadius = "40px"
      })

      // for (item in frame) {
      //   console.log(item)
      // }

      // if (frame !== null) {
      //   frame.style.width = '100%'
    },
    onPlayerEnded(lessonId) {
      viewLesson(this.$store.state.bus.curCourse, lessonId + 1)
    }
  },


  created() {
    this.childVideos = this.$store.state.user.childVideos
    this.studentVideos = this.$store.state.user.studentVideos
  },

  computed: {
    toggle() {
      return this.$store.state.bus.toggler
    }
  },

  watch: {
    toggle() {
      this.secondStep = []
    }
  }
}


</script>

<style lang="scss" scoped>
@import "../assets/scss/variables";

$input-border-color: #DBDBDB;
$input-bg-color: #FFFFFF;
$input-text-color: #666;

.header {
  width: 75%;
  display: block;
  margin: 0 auto;
  margin-top: 25px;

  img {
    width: 100%;
  }
}

.academy {
  background-color: #fff;
  border-radius: 40px;
  margin: 20px 8% 20px 8%;
  padding: 40px 30px 40px 30px;
  background-image: url("../assets/svg/academy_bg.svg");
  background-size: 101%;
  background-repeat: no-repeat;
  text-align: center;
  background-position: center center;

  h2 {
    color: $purple;
    padding: 0px 15% 20px 15%;
  }

  p {
    padding: 0px 20% 20px 20%;
  }

  .video {
    width: 700px;
    margin: 0 auto;
    height: 400px;
    border-radius: 40px;

    iframe {
      border-radius: 40px;
      border: none;
    }
  }
}

.age {
  background-color: $yellow;
  border-radius: 40px;
  margin: 20px 8% 20px 8%;
  padding: 40px 30px 40px 30px;
  background-size: 101%;
  background-repeat: no-repeat;
  text-align: center;
  background-position: center center;

  h2 {
    color: $purple;
    padding: 0px 15% 20px 15%;
  }

  p {
    padding: 0px 20% 20px 20%;
  }
}

.categories {
  margin: 20px 8% 20px 8%;
  padding: 40px 30px 40px 30px;
  text-align: center;
  background-position: center center;

  .category-item:hover {
    background-color: $orange;
  }

  .category-item {
    background-color: #fff;
    border-radius: 40px;
    width: 42%;
    margin: 15px 1% 15px 1%;
    padding: 2%;
    position: relative;
    transition: all 500ms;
    display: inline-block;
    max-height: 150px;

    img {
      width: 30%;
      display: block;
      max-height: 150px;
    }

    .category-item--right {
      width: 60%;
      display: inline-block;
      position: absolute;
      top: 50%;
      right: 1%;
      transform: translateY(-50%);

      .title {
        color: #3F3F3F;
        margin-bottom: 15px;
        font-size: 18px;
      }
    }
  }
}

.category-videos {
  background-color: #fff;
  border-radius: 40px;
  margin: 20px 8% 20px 8%;
  padding: 40px 30px 40px 30px;
  background-image: url("../assets/svg/academy_bg.svg");
  background-size: 101%;
  background-repeat: no-repeat;
  text-align: center;
  background-position: center center;

  h2 {
    color: $purple;
    padding: 0px 15% 20px 15%;
  }

  h1 {
    color: $green;
    padding: 0;
    font-size: 36px;
  }

  p {
    padding: 0px 20% 20px 20%;
  }

  .video.disabled {
    pointer-events: none;
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    filter: grayscale(100%);
  }

  .video {
    width: 38%;
    display: inline-block;
    margin: 0 auto;
    margin-left: 2%;
    margin-right: 2%;
    border-radius: 40px;
    background-color: $orange;
    border: 6px solid $orange;

    iframe {
      border-radius: 40px;
      border: none;
    }

    .title, .button {
      margin: 15px 0 15px 0;
    }
  }

  .download-link {
    color: #000;
    width: 50%;
    text-align: left;
    display: block;
    margin: 0 auto;
    img {
      display: inline-block;
      width: 30px;
      margin-bottom: -9px;
      margin-right: 10px;
    }
  }
}

a {
  text-decoration: none;
}

h1, h2, h3 {
  text-align: center;
}

h1 {
  font-size: 60px;
  font-weight: 500;
  color: $white;
}

.wrapper {
  background-color: $green;
}

.button {
  width: 276px;
  height: 60px;
  font-family: "Marvin Round";
  font-size: 20px;
  line-height: 60px;
  text-align: center;
  text-transform: uppercase;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  margin: 0 auto;
  margin-left: 5px;
  margin-right: 5px;
  display: inline-block;
}

.button-main {
  background-color: $purple;
  color: $white;

  &:hover {
    background-color: $purple;
  }
}

@media screen and (max-width: 992px) {
  h1 {
    font-size: 36px;
  }

  .feedback .form {
    background-color: transparent;
    width: 100%;
  }

  .feedback input {
    width: 95%;
  }

  .academy, .age,
  .categories,
  .category-videos {
    padding: 0;
    margin: 0;
    width: 100%;
  }

  .academy {
    border-radius: 0;
    padding-bottom: 25px;

    .video {
      width: 90%;
      height: 200px;
      margin: 0 auto;
    }
  }

  .age {
    padding-bottom: 25px;
    margin-bottom: 50px;
    .button {
      width: 33%;
    }
  }

  .categories {
    .category-item {
      width: 90%;

      .button-main {
        width: 220px;
      }
    }
  }

  .category-videos {
    border-radius: 0;
    padding-bottom: 50px;

    .video {
      width: 90%;
      margin-bottom: 25px;
    }

    h2 {
      padding: 0;
    }

    .download-link {
      width: 80%;
    }
  }
}
</style>